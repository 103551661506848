<template lang="pug">
    .info-box.pa-5
        .max-width
            .disclaimer(v-if="!showNotes").text-center
                .mb-2
                    small We care about your privcy and confidentiality
                v-btn(:block="$vuetify.breakpoint.smAndDown" small @click="showNotes = true" text color="black").font-weight-bold Learn More

            v-expand-transition
                #notes.notes(v-if='showNotes').pa-6
                  h2.text-medium.text-center.mb-5 A few important points
                  ul.small
                      li Each submission costs 1 or more credits. You can see how many credits will be used.
                      li Do not submit any confidential information or private details about employees, customers or users until after you have signed with us an <router-link to="/pages/static/confidentiality">NDA (non-disclosure agreement)</router-link>
                      li After you submit your text, you will receive an email confirmation.
                      li You'll receive a second email when your text is ready to review in your <router-link to="/inbox/all_submissions">inbox</router-link>.
                      li Your text will be checked for grammar, and we'll answer the question <em>"Does this make sense in English?"</em>.

                  .text-center.mt-5
                      v-btn(small color="secondary" outlined @click="showNotes = false").font-weight-bold I understand
</template>

<script>
import { scrollToTarget } from '@/helpers'
export default {
  name: 'FaqNotes',
  data() {
    return { showNotes: false }
  },
  watch: {
    showNotes() {
      this.$nextTick(() => {
        setTimeout(() => {
          const notes = document.getElementById('notes')
          if (notes && this.$vuetify.breakpoint.smAndDown) { scrollToTarget(notes) }
        }, 200)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.info-box .notes{
  border: 1px solid rgb(195, 234, 255);
  border-radius:3px;
}
li {margin-bottom:1rem;}

.max-width{
  max-width:750px;
  margin:auto;
}
</style>
