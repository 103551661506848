<template lang="pug">
    v-navigation-drawer(color="#0F1E25" app permanent :value="true" hide-overlay width="300px")
        .links.pa-6
            router-link(to="/inbox/all_submissions")
              v-img(src="@/assets/logo_light.svg" min-width="220px", :min-height="$vuetify.breakpoint.mdAndUp ? '120px' : '70px'" contain max-width="150px").mx-auto.mt-3.mb-12

            template(v-if="!userData.isEditor")
              v-btn.link.btn-submit(color="primary" block x-large @click="navTo('/inbox/action/submit')").px-4.mb-4.justify-center
                  v-icon.icon.mr-2 mdi-send-circle-outline
                  .text.white--text Submit Text

              v-divider.my-6

            v-btn.link(v-for="item in items", :key="item.text" text block :color="calcLinkColor(item.type)", @click="navTo('/inbox/' + item.type.toLowerCase())").mb-6.justify-start
                v-icon.icon.mr-2 {{item.icon}}
                .label.d-flex.white--text
                  .text(:style="{color: calcLinkColor(item.type)}") {{item.text}}
                  .count.ml-2
                      small.white--text ({{counts[toCamelCase(item.type)]}})

            v-divider.my-6
            v-btn.link(text color="grey darken-2" :color="calcLinkColor('USAGE')", @click="navTo('/inbox/action/profile')").mb-3 Profile & Credits
            v-btn.link(text color="grey darken-2" :color="calcLinkColor('NDA')", @click="navTo('/pages/static/confidentiality')") Privacy & Confidentiality
            //- pre pending = {{pending}}
            //- pre submissions = {{submissions}}
</template>

<script>
import lodashCamelcase from 'lodash.camelcase'
import { get, call } from 'vuex-pathify'
export default {
  name: 'Nav',
  data() {
    return {}
  },
  created() {
    this.init()
  },
  mounted() {},
  computed: {
    ...get({
      activeComponent: 'nav/activeComponent',
      submissions: 'submissions/submissions',
      completed: 'submissions/completed',
      recentlyReviewed: 'submissions/recentlyReviewed',
      pending: 'submissions/pending',
      archived: 'submissions/archived',
      userData: 'user/userData'
    }),
    counts() {
      const allSubmissions = this.submissions.length
      const completed = this.completed ? this.completed.length : 0
      const pending = this.pending ? this.pending.length : 0
      const archived = this.archived ? this.archived.length : 0
      const recentlyReviewed = this.recentlyReviewed
        ? this.recentlyReviewed.length
        : 0
      return {
        allSubmissions,
        completed,
        pending,
        archived,
        recentlyReviewed
      }
    },
    items() {
      const lib = {
        completed: {
          text: 'Feedback ready',
          icon: 'mdi-inbox-arrow-down',
          type: 'COMPLETED'
        },
        pending: {
          text: 'Waiting for review',
          icon: 'mdi-clock-outline',
          type: 'PENDING'
        },
        archived: {
          text: 'Archive',
          icon: 'mdi-archive-arrow-down-outline',
          type: 'ARCHIVED'
        },
        recentlyReviewed: {
          text: 'Recently Reviewed',
          icon: 'mdi-inbox-arrow-down',
          type: 'RECENTLY_REVIEWED'
        },
        all: {
          text: 'All Submissions',
          icon: 'mdi-expand-all',
          type: 'ALL_SUBMISSIONS'
        }
      }

      const { completed, pending, archived, recentlyReviewed, all } = lib
      if (this.userData.isEditor) return [pending, recentlyReviewed]
      else return [completed, pending, archived, all]
    }
  },
  methods: {
    ...call({
      bindSubmissionsRef: 'submissions/bindSubmissionsRef',
      navTo: 'nav/navTo'
    }),
    init() {
      if (this.submissions && this.submissions.length > 0) {
        this.initComplete = true
      } else {
        this.refresh()
      }
    },
    calcLinkColor(type) {
      if (!this.activeComponent) return 'rgba(255,255,255,0.7)'
      else if (this.activeComponent.type === type) return 'white'
      else {
        return 'rgba(255,255,255,0.65)'
      }
    },
    async refresh() {
      this.initComplete = false
      await this.bindSubmissionsRef()

      this.$nextTick(() => {
        setTimeout(() => {
          this.initComplete = true
          window.scrollTo(0, 1)
        }, 1500)
      })
    },
    toCamelCase: str => lodashCamelcase(str)
  }
}
</script>

<style lang="scss" scoped>
// .sidebar {
//   // background: white;
//   background: #0f1e25;
//   min-height: 100vh;
// }

.submit {
  background: rgba(110, 182, 214, 0.9);
  background: linear-gradient(
    103deg,
    rgb(96, 179, 215) 0%,
    rgb(70, 188, 242) 100%
  );
}

.link {
  font-weight: bold;
  // font-size:0.87rem;
  letter-spacing: 0.5px;
  .text {
    text-transform: normal !important;
  }
}

.btn-submit {
  text-shadow: 1px 1px rgba(9, 72, 101, 0.3);
}
</style>
