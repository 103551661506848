<template lang="pug">
    .incoming-dashboard.d-flex.d-print-block
        .sidebar(v-if="$vuetify.breakpoint.mdAndUp").d-print-none
            Nav
        .main-area.px-2.py-md-6.px-xl-12
            //- pre {{userData}}
            .max-width
                .primary-card.color-strip(:class="[calcColorStripClass, {'color-strip--light': $vuetify.breakpoint.smAndDown}]")
                  .pa-4.pa-md-12
                    v-expand-transition
                      component(:is="activeComponent.component", :type="activeComponent.type", :key="activeComponent.type")
                FaqNotes.mt-3.mt-md-12

</template>

<script>
import { get, call } from 'vuex-pathify'
import Nav from '@/components/Incoming/Nav.vue'
import FaqNotes from '@/components/Incoming/FaqNotes.vue'
import { capitalize } from '@/helpers'
export default {
  name: 'IncomingDashboard',
  components: { Nav, FaqNotes },
  title: 'PROIT English – Dashboard',
  watch: {
    $route(to) {
      this.init()
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...get({ activeComponent: 'nav/activeComponent', userData: 'user/userData' }),
    calcColorStrip() {
      if (this.activeComponent.type === 'COMPLETED') return 'completed-strip'
      else if (this.activeComponent.type === 'PENDING') return 'pending-strip'
      else if (this.activeComponent.type === 'ARCHIVED') return 'archived-strip'
      else if (this.activeComponent.type === 'USAGE') return 'profile-strip'
      else if (this.activeComponent.type === 'ALL_SUBMISSIONS') return 'all-strip'
      else return null
    },
    calcColorStripClass() {
      if (!this.activeComponent || !this.activeComponent.type) return null
      return 'color-strip--' + this.activeComponent.type.toLowerCase().split('_').join('-')
    }
  },
  created() {
    this.setTitle('Dashboard')
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    ...call({
      showMessageList: 'nav/showMessageList',
      showCompose: 'nav/showCompose',
      showUsage: 'nav/showUsage',
      showReview: 'nav/showReview',
      showEvaluate: 'nav/showEvaluate',
      showPage: 'nav/showPage'
    }),
    async init() {
      const { type, action, id } = this.$route.params

      if (type) {
        if (type.length > 1) { this.setTitle(capitalize(type.toLowerCase().split('_').join(' '))) }
        this.showMessageList({ type: type.toUpperCase() })
      } else await this.showAction({ action, id })

      window.scrollTo(0, 1)
    },
    async showAction({ type, action, id }) {
      if (action) {
        if (action.toUpperCase() === 'SUBMIT') {
          this.setTitle('New Text')
          await this.showCompose()
        } else if (action.toUpperCase() === 'PROFILE') {
          this.setTitle('Profile')
          await this.showUsage()
        } else if (action.toUpperCase() === 'REVIEW') {
          this.setTitle('Review')
          await this.showReview()
        } else if (action.toUpperCase() === 'EVALUATE') {
          this.setTitle('Evaluate')
          await this.showEvaluate()
        } else if (action.toUpperCase() === 'STATIC') {
          await this.showPage({ id })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.max-width {
  max-width: 1400px;
}
.incoming-dashboard {
  min-height: 100vh;
  background: white;
}

.main-area {
  width: 100%;
  background: #f7fcfc;
}

.primary-card {
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px;
}
.sidebar {
  border-right: 1px solid #e3e7f7;
}

.color-strip {
  border-top: 8px solid;
  border-top-color: gray;
}

.color-strip--light{
  border-top-color:#e3ebeb;
}

.color-strip--completed {
  border-top-color:$completed;
}
.color-strip--pending {
  border-top-color: $pending;
}
.color-strip--archived {
  border-top-color:$archived;;
}
.color-strip--usage {
  border-top-color: $profile;
}
.color-strip--all-submissions{
  border-top-color: $allSubmissions;
}

</style>
